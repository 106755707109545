import { apolloProvider } from "@/main";
import ADD_TO_CART from "@/graphql/mutations/ProductsAddToShoppingCart.gql";
import { errorHandler } from "@/plugins/mixins";
import CHECKOUT_TARIFF_MUTATION from "@/graphql/mutations/modules/ModuleProductsTariffsCheckout.gql";
import CHECKOUT_UPGRADE_TARIFF_MUTATION from "@/graphql/mutations/modules/ModuleProductsUpgradeCheckout.gql";
import { isRecurrentPricesInProduct } from "@/utils/products/isRecurrentPricesInProduct";
import PRODUCTS_LIST from "@/graphql/queries/Products.gql";
export default {
  async getProducts({ rootGetters }) {
    const { data } = await apolloProvider.defaultClient.query({
      query: PRODUCTS_LIST,
      variables: { whitelabel_uuid: rootGetters["app/getConfig"]?.uuid },
    });
    return data?.moduleProducts;
  },
  async productHandler({ commit, dispatch }, product) {
    // Step 1: Recurrent payments
    if (isRecurrentPricesInProduct(product) && !product.billing_cycle) {
      commit("setRecurrentProduct", product);
      commit("setRecurrentPricesModalIsOpen", true);
      return;
    }
    if (
      isRecurrentPricesInProduct(product) &&
      product.billing_cycle &&
      product.billing_cycle === "onetime"
    ) {
      commit("setRecurrentProduct", product);
      commit("setRecurrentPricesModalIsOpen", true);
      return;
    }
    // Step 2: Checkout or Add to Cart
    await dispatch("checkoutOrAddToCart", {
      product,
      cycle: product.billing_cycle,
    });
  },
  async checkoutOrAddToCart(
    { rootGetters, dispatch },
    { product, cycle = "onetime" }
  ) {
    if (rootGetters["modules/products/isTariffs"]) {
      if (product.currentPurchaseUuid && product.billing_cycle) {
        await dispatch("checkoutUpgradeTariff", {
          uuid: product.uuid,
          callback: product.tariffCallback,
          currentPurchaseUuid: product.currentPurchaseUuid,
          billingCycle: cycle,
        });
      } else {
        await dispatch("checkoutTariff", {
          id: product.id,
          callback: product.tariffCallback,
          cycle,
        });
      }
    } else {
      await dispatch("addToCart", {
        id: product.id,
        cycle,
      });
    }
  },
  async addToCart({ commit, rootGetters }, { id, cycle }) {
    try {
      await apolloProvider.defaultClient.mutate({
        mutation: ADD_TO_CART,
        variables: {
          whitelabel_uuid: rootGetters["app/getConfig"]?.uuid,
          ...(rootGetters["app/getIsLoggedIn"]
            ? {}
            : { anonymous_id: rootGetters["app/getAnonymousId"] }),
          product_id: id,
          input: {
            qty: 1,
            billing_cycle: cycle,
          },
        },
      });
      window.dispatchEvent(new CustomEvent("CART_UPDATE"));
      commit("cart/setCartOpen", true, { root: true });
      if (rootGetters["app/getShowRegisterModalAfterRecurrent"]) {
        commit("app/setRegisterModalOpen", true, { root: true });
        commit("app/setShowRegisterModalAfterRecurrent", false, { root: true });
      }
    } catch (e) {
      errorHandler(e);
    }
  },
  async checkoutTariff({ rootGetters }, { id, callback, cycle }) {
    try {
      const { data } = await apolloProvider.defaultClient.mutate({
        mutation: CHECKOUT_TARIFF_MUTATION,
        variables: {
          whitelabel_uuid: rootGetters["app/getConfig"]?.uuid,
          product_id: id,
          billing_cycle: cycle,
        },
      });
      const invoiceId = data?.moduleProductsTariffsCheckout?.invoice_id;
      callback(invoiceId);
    } catch (e) {
      errorHandler(e);
    }
  },
  async checkoutUpgradeTariff(
    { rootGetters },
    { uuid, callback, currentPurchaseUuid, billingCycle }
  ) {
    try {
      const { data } = await apolloProvider.defaultClient.mutate({
        mutation: CHECKOUT_UPGRADE_TARIFF_MUTATION,
        variables: {
          whitelabel_uuid: rootGetters["app/getConfig"]?.uuid,
          current_purchase_uuid: currentPurchaseUuid,
          new_product_uuid: uuid,
          billing_cycle: billingCycle,
        },
      });
      const invoice = data?.moduleProductsUpgradeCheckout;
      callback(invoice);
    } catch (e) {
      errorHandler(e);
    }
  },
};
