<template>
  <v-container v-if="!$apollo.loading && me" fluid>
    <v-layout wrap>
      <v-flex xs12 pt-8 pb-0>
        <v-card-title>{{ $t("security.twofa") }}</v-card-title>
        <p class="twoFa-text">
          <icon-custom
            name="icn20_info_square"
            color="var(--surface-color-30)"
          />
          {{ $t("security.twofaDesc") }}
        </p>
        <v-divider class="mb-5" />
      </v-flex>
      <v-flex md6 xs12 align-center d-flex >
        <v-layout class="align-center justify-space-between ma-0" style="gap: 24px;">
          <small> {{ $t("security.status") }}: &nbsp; </small>
          <v-chip label small :color="me.twofa ? 'success' : ''">
            <b>{{
              me.twofa ? $t("security.active") : $t("security.notActive")
            }}</b>
          </v-chip>
          <small v-if="me.twofa">
            {{ $t("security.method") }}: &nbsp;
            <b>{{
              me.twofa_method
                ? $t("security." + me.twofa_method)
                : $t("security.notSpecified")
            }}</b>
          </small>
        </v-layout>
      </v-flex>
      <v-flex d-flex justify-end md6 xs12>
        <v-btn
          text
          primary
          class="primary"
          @click="
            !me.twofa ? (twofaDialog = true) : (deactivateTwofaDialog = true)
          "
        >
          {{ !me.twofa ? $t("security.activate") : $t("security.deactivate") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="mt-5" />
    <two-fa-dialog
      v-if="twofaDialog"
      :show-dialog="twofaDialog"
      @closeTwoFaDialog="closeTwoFaDialog"
    />
    <deactivate-two-fa-dialog
      v-if="deactivateTwofaDialog"
      :show-dialog="deactivateTwofaDialog"
      :twofa-method="me.twofa_method"
      @closeDeactivateTwoFaDialog="closeDeactivateTwoFaDialog"
    />
  </v-container>
</template>

<script>
import TwoFaDialog from "@/components/core/Security/partial/TwoFaDialog.vue";
import DeactivateTwoFaDialog from "@/components/core/Security/partial/DeactivateTwoFaDialog.vue";
import IconCustom from "@/components/helper/IconCustom";

export default {
  name: "TwoFaView",
  components: {
    IconCustom,
    TwoFaDialog,
    DeactivateTwoFaDialog,
  },
  data() {
    return {
      twofaDialog: false,
      deactivateTwofaDialog: false,
      loading: false,
    };
  },
  methods: {
    closeTwoFaDialog(val) {
      this.twofaDialog = val;
      this.$apollo.queries.me.refetch();
    },
    closeDeactivateTwoFaDialog(val) {
      this.deactivateTwofaDialog = val;
      this.$apollo.queries.me.refetch();
    },
  },
  apollo: {
    me: {
      query: require("@/graphql/queries/Me.gql"),
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/material-dashboard/_colors.scss";

.dashboard {
  .layout.wrap .v-card__title {
    margin-bottom: 15px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: var(--surface-color);
  }
  .v-card__text {
    padding: 3px;
    .v-avatar {
      border-radius: 50% !important;
      background: #173eb7;
      margin-top: -2px;
      .v-icon {
        color: $grey-100;
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      margin: 10px;
      color: $black;
    }
  }
  .twoFa-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 13px;
    & > .svg-icon {
      margin-right: 8px;
    }
  }
  .v-chip,
  .v-btn {
    border-radius: 12px !important;
  }
}
</style>
