<template>
  <v-btn
    class="u-btn"
    v-bind="$attrs"
    :class="[$attrs.color]"
    :ripple="false"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "UBtn",
};
</script>

<style lang="scss" scoped>
.u-btn::v-deep {
  &.v-btn {
    transition: 0.4s !important;

    // Disabled state
    &.v-btn--disabled {
      opacity: 0.4;
    }

    // Button color variants
    &.v-btn--has-bg {
      background: var(--surface-color-03, rgba(49, 57, 44, 0.1)) !important;
      color: var(--surface-color) !important;
    }

    &.light {
      background: var(--surface-color-10, rgba(49, 57, 44, 0.1)) !important;
      color: var(--surface-color) !important;
    }

    &.action-light {
      background: var(--surface-color-03) !important;
      color: var(--secondary-color) !important;
    }

    &.green {
      background: var(--common-green) !important;
      color: var(--common-white) !important;
    }

    &.background {
      background: var(--surface-background) !important;
      color: var(--surface-color) !important;
    }

    &.transparent {
      background: transparent !important;
      color: var(--surface-color) !important;
    }

    &.primary {
      background-color: var(--primary-color) !important;
      color: var(--primary-text-color) !important;
    }

    // Outlined style
    &.v-btn--outlined {
      background: transparent !important;
      border: 1px solid transparent; // Default border style
      color: var(--primary-color) !important;

      // When button has primary color
      &.primary {
        border-color: var(--primary-color) !important;
        color: var(--surface-color) !important;
      }

      &.purple-transparent {
        background-color: var(--purple-transparent) !important;
        color: #fff!important;
        border-color: var(--purple-border)!important;
      }
    }
  }
}

</style>
