export default {
  getUser: (state) => state?.user,
  getBalances: (state) => {
    const addTotal = (item) => ({
      ...item,
      currencySymbol: item.symbol,
      total: item?.available + item?.locked,
    });
    const balanceMutator = (balance) => addTotal(balance);
    return state?.user?.wallet_balance?.map(balanceMutator);
  },
  getUserPartnerProgram(state) {
    return state.isUserPartnerProgram;
  },
  getModuleDefiIndexStats(state) {
    return state.moduleDefiIndexStats;
  },
  getModuleDefiIndexes(state) {
    return state.moduleDefiIndexes;
  },
  getFilteredWallets: (_, { getBalances }) => {
    const wallets = getBalances?.filter(
      (wallet) => wallet.currency !== "BTC" && wallet.currency !== "AIV"
    );
    return wallets?.length !== 0 ? wallets : null;
  },
  getMainBalance: (_, { getBalances }, __, rootGetters) => {
    const wallet = getBalances?.find?.(
      (wallet) => wallet?.currency === rootGetters?.["app/getCurrency"]
    );
    return wallet?.available || null;
  },
  getMainWallet: (_, { getBalances }, __, rootGetters) => {
    const wallet = getBalances?.find(
      (wallet) => wallet.currency === rootGetters?.["app/getCurrency"]
    );
    return wallet ?? null;
  },
  getWalletToken: (_, { getBalances }) => {
    return getBalances?.filter(
      (wallet) =>
        wallet.currency !== "USDT" &&
        wallet.currency !== "BTC" &&
        wallet.currency !== "AIV" &&
        wallet.type !== "fiat" &&
        wallet.type !== "crypto"
    );
  },
  getWalletsFiat: (_, { getBalances, getMainWallet }) => {
    return (
      getBalances?.filter(
        (wallet) =>
          wallet.currency !== getMainWallet?.currency &&
          wallet.currency !== "BTC" &&
          wallet.currency !== "AIV" &&
          wallet.type === "fiat"
      ) ?? []
    );
  },
  getWalletsCrypto: (_, { getBalances, getMainWallet }) => {
    return (
      getBalances?.filter(
        (wallet) =>
          wallet.currency !== getMainWallet?.currency &&
          wallet.currency !== "BTC" &&
          wallet.currency !== "AIV" &&
          wallet.type === "crypto"
      ) ?? []
    );
  },
  getWalletsSupport: (_, { getBalances, getMainWallet }) => {
    return (
      getBalances?.filter(
        (wallet) =>
          wallet.currency !== "BTC" &&
          wallet.currency !== "AIV" &&
          wallet.currency !== getMainWallet?.currency &&
          (wallet.type === "fiat" || wallet.type === "crypto")
      ) ?? []
    );
  },
  getBalancesITO: (_, { getBalances }) => {
    const walletITO = getBalances?.filter(
      (wallet) =>
        wallet.currency !== "USDT" &&
        wallet.currency !== "BTC" &&
        wallet.currency !== "AIV"
    );
    return walletITO?.length !== 0 ? walletITO : null;
  },
  getUserSettings: (state) => state.userSettings,
  isUserSuspended: (state) => state.userSettings?.status === "suspended",
  isUserSuspendedTrading: (state, { isUserSuspended }) =>
    isUserSuspended || state.user?.security?.trading_suspended,
  getSubscription: (state) => state.subscription,
  getPurchase: (state) => state.user.purchase,
};
