<template>
  <v-text-field
    v-if="isEnabled"
    v-model.trim="promoCode"
    class="promoCode-input"
    hide-details
    name="promoCode"
    :label="$t('promoCodes.enterPromoCode')"
    outlined
  >
    <template #append>
      <v-btn
        v-if="promoCode"
        small
        color="primary"
        class="validation-button"
        :disabled="loading"
        :loading="loading"
        @click="validateCode"
      >
        {{ $t("promoCodes.apply") }}
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VALIDATE_PROMO_CODE_MUTATION from "@/graphql/mutations/modules/PromoCodes/ModulePromoCodesCheck.gql";

export default {
  name: "PromoCodeValidation",
  data() {
    return {
      promoCode: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isEnabled: "modules/promoCodes/isEnabled",
      getValidCode: "modules/promoCodes/getValidCode",
      getProductIdsForValidCode: "modules/promoCodes/getProductIdsForValidCode",
      getCartUniqueIds: "cart/getCartUniqueIds",
      getWhiteLabelUuid: "app/getWhiteLabelUuid",
    }),
  },
  watch: {
    getCartUniqueIds: {
      handler(val) {
        if (
          val &&
          this.getProductIdsForValidCode &&
          val.some((id) => !this.getProductIdsForValidCode.includes(id))
        ) {
          this.clearValidPromoCode();
        }
        this.promoCode = this.getValidCode && this.getValidCode.code;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("modules/promoCodes", [
      "setValidCode",
      "setProductIdsForValidCode",
    ]),
    clearValidPromoCode() {
      this.setValidCode(null);
      this.setProductIdsForValidCode(null);
    },
    async validateCode() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: VALIDATE_PROMO_CODE_MUTATION,
          variables: {
            promocode: this.promoCode,
            whitelabel_uuid: this.getWhiteLabelUuid,
            product_ids: this.getCartUniqueIds,
          },
        });
        this.setValidCode(data.modulePromoCodesCheck);
        this.setProductIdsForValidCode([...this.getCartUniqueIds]);
      } catch (e) {
        this.errorHandler(e);
        this.clearValidPromoCode();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.promoCode-input {
  margin: 15px 0 17px 0;

  ::v-deep .v-input__append-inner {
    margin-top: 14px !important;
  }
}

.validation-button {
  font-weight: 600 !important;
  font-size: 11px !important;
  padding: 10px 12px !important;
}
</style>
