// https://vuex.vuejs.org/en/state.html

export default {
  user: null,
  userSettings: null,
  subscription: null,
  moduleDefiIndexStats: null,
  moduleDefiIndexes: null,
  isUserPartnerProgram: null
};
