<template>
  <div v-if="affSponsor" class="my-sponsor" :class="{ mini }">
    <div class="divider" />
    <div v-if="affSponsor && showSponsor" class="sponsor-data">
      <v-tooltip content-class="left" left>
        <template #activator="{ on }">
          <div v-on="mini && on">
            <avatar
              :avatar="affSponsor.avatar"
              :name="affSponsor.name"
              :size="mini ? 56 : 40"
            />
          </div>
        </template>
        {{ affSponsor.name }}
      </v-tooltip>
      <div v-if="mini" class="sponsor-label">
        {{ $t("info.sponsor") }}
      </div>
      <div v-if="!mini" class="sponsor-data__details">
        <div class="label">
          <span>{{ $t("info.mySponsor") }}</span>
        </div>
        <div class="name">
          <span>{{ affSponsor.name }}</span>
        </div>
        <a
          v-if="mySponsorEmail"
          target="_blank"
          class="my-sponsor__link"
          :href="mySponsorEmail"
        >
          Email: {{ affSponsor.email }}
        </a>
        <a
          v-if="mySponsorTelegram"
          target="_blank"
          class="my-sponsor__link"
          :href="mySponsorTelegram"
        >
          Telegram: @{{ affSponsor.telegram }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AFFSPONSOR_QUERY from "@/graphql/queries/AffSponsor.gql";
import Avatar from "@/components/core/Avatar.vue";

export default {
  name: "MySponsor",
  components: {
    Avatar,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    showSponsor() {
      return this.$ls.get("config").show_sponsor_profile;
    },
    mySponsorEmail() {
      if (!this.affSponsor || !this.affSponsor?.email) return "";
      return `mailto:${this.affSponsor.email}`;
    },
    mySponsorTelegram() {
      if (!this.affSponsor || !this.affSponsor?.telegram) return "";
      return `https://t.me/${this.affSponsor?.telegram}`;
    },
  },
  apollo: {
    affSponsor: {
      query: AFFSPONSOR_QUERY,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  margin: 12px 0;
}
.my-sponsor {
  &__link {
    font-size: 11px;
    font-weight: 600;
  }
  &.mini {
    .sponsor-data {
      flex-direction: column;
      gap: 8px;
      .sponsor-label {
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }

  display: flex;
  flex-direction: column;
  gap: 12px;
}
.sponsor-data {
  display: flex;
  gap: 16px;

  .sponsor-data__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 2px;
    .label {
      font-size: 10px;
      font-weight: 400;
      line-height: 130%;
    }
    .name {
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}
</style>
